<template>
	<Document
		:fileOpFunc="fileOpFunc"
		:defaultDoc="defaultDoc"
		:previewUrl="previewUrl"
		:downloadUrl="downloadUrl"
		:projectId="$route.params.projectId"
	></Document>
</template>

<script>
import Document from "@/components/BusinessComponents/Document/Index.vue"
import api from "@/api/project/contract"

const {
	apiGetPage,
	deleteFile,
	addFile,
	replaceFile,
	getLogs,
	toggleFile,
	uploadFile,
	apiGetPermission,
	apiSetPermission,
	getTreeList
} = api
export default {
	name: "proDocument",
	data() {
		return {
			fileOpFunc: {
				apiGetPage,
				deleteFile,
				addFile,
				replaceFile,
				getLogs,
				toggleFile,
				uploadFile,
				apiGetPermission,
				apiSetPermission,
				getTreeList
			},
			previewUrl: `${this.$baseUrl}/project/${this.$route.params.projectId}/contract/docfile`,
			downloadUrl: `${this.$baseUrl}/project/${this.$route.params.projectId}/contract/docfile`,
			defaultDoc: {
				id: "3",
				name: "合同管理"
			}
		}
	},
	components: {
		Document
	}
}
</script>

<style scoped></style>
